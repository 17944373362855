import { Box } from "@mui/material";
import {
  DataGridPremium,
  GridToolbar,
  LicenseInfo,
  useGridApiRef,
  GridPagination,
  GridToolbarContainer,
  GridFooter,
} from "@mui/x-data-grid-premium";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { API_BASE } from "@/App.js";
import CommentsDrawer from "@/components/CommentsDrawer";
import { getColumns } from "./columns";
import MoveTargets from "./MoveTargets";

LicenseInfo.setLicenseKey(
  "d6170662ba5642dff2dfd6ab7ddc75f9Tz0xMDI1NDYsRT0xNzY0MTY3MDkzMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y"
);

function CustomToolbar() {
  // want both the toolbar and the pagination to be in the same row
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: 1,
        maxWidth: "calc(100vw - 240px)",
      }}
    >
      <GridToolbar />
      <GridPagination />
    </GridToolbarContainer>
  );
}

// Simple localStorage wrapper
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

export default function TargetTableContainer({
  searchData,
  targets,
  setTargets,
  fetchTargetCount,
}) {
  const [searchParams] = useSearchParams();
  const currentView = searchParams.get("currentView");
  const [showComments, setShowComments] = useState(false);
  const [target, setTarget] = useState(null);
  const apiRef = useGridApiRef();

  const [selectionModel, setSelectionModel] = useState([]);

  const [filters, setFilters] = useLocalStorage(`${searchData.uid}_filters`, {
    items: [],
  });

  const handleCommentClick = useCallback((row) => {
    setShowComments((prev) => !prev);
    setTarget(row);
  }, []);

  const handleUpdate = useCallback(
    async (newRow, oldRow) => {
      const changes = Object.entries(newRow).reduce((acc, [key, value]) => {
        if (oldRow[key] !== value) {
          acc[key] = value;
        }
        return acc;
      }, {});

      try {
        axios.post(`${API_BASE}update_company`, {
          search_uid: searchData.uid,
          domain: newRow.domain,
          data: changes,
          actor_key: localStorage.getItem("actorKey"),
        });
        return newRow;
      } catch (error) {
        console.error("Error updating row:", error);
        return oldRow;
      }
    },
    [searchData.uid]
  );

  const handleFilterChange = useCallback(
    (newFilters) => {
      setFilters(newFilters);
    },
    [setFilters]
  );

  const columns = useMemo(
    () =>
      getColumns(
        searchData,
        handleCommentClick,
        targets,
        setTargets,
        fetchTargetCount
      ),
    [searchData, handleCommentClick, targets, setTargets, fetchTargetCount]
  );

  const [columnOrder, setColumnOrder] = useLocalStorage(
    `${searchData.uid}_columnOrder`,
    columns.map((c) => c.field)
  );

  const orderedColumns = useMemo(
    () =>
      columnOrder.map(
        (field) => columns.find((c) => c.field === field) || { field }
      ),
    [columnOrder, columns, targets]
  );

  const handleColumnOrderChange = useCallback(() => {
    const newColumnOrder = apiRef.current
      .getAllColumns()
      .map((column) => column.field);
    setColumnOrder(newColumnOrder);
  }, [apiRef, setColumnOrder]);

  return (
    <Box
      sx={{
        mt: 1,
        mb: 2,
        mr: "100px",
        // width: "calc(100vw - 140px)",
        "& .MuiDataGrid-root": { borderColor: "rgba(19,19,19)" },
      }}
    >
      <CommentsDrawer
        showComments={showComments}
        setShowComments={setShowComments}
        searchData={searchData}
        target={target}
        setTarget={setTarget}
      />

      {selectionModel.length > 0 && (
        <MoveTargets
          searchData={searchData}
          targets={targets}
          setTargets={setTargets}
          fetchTargetCount={fetchTargetCount}
          currentView={currentView}
          apiRef={apiRef}
          selectionModel={selectionModel}
          setSelectionModel={setSelectionModel}
        />
      )}

      <Box sx={{ height: `calc(100vh - 220px)`, width: "100%" }}>
        <DataGridPremium
          apiRef={apiRef}
          rows={targets}
          columns={orderedColumns}
          getRowId={(row) => row.domain}
          density="compact"
          processRowUpdate={handleUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          slots={{ toolbar: CustomToolbar }}
          pagination
          hideFooter
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
            sorting: { sortModel: [{ field: "employees", sort: "desc" }] },
            filter: { filterModel: filters },
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          cellSelection
          checkboxSelection
          // ignoreValueFormatterDuringExport
          onColumnOrderChange={handleColumnOrderChange}
          onFilterModelChange={handleFilterChange}
          onRowSelectionModelChange={(newSelection) => {
            console.log("newSelection", newSelection);
            setSelectionModel(newSelection);
          }}
          selectionModel={selectionModel}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
}
