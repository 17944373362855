import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import Home from "./pages/Home";
import SearchResults from "./pages/SearchResults";
import Reporting from "./pages/Reporting";
import Login from "./pages/Login";
import { getApiBase, getHelmetTitle } from "./utils";

const clientId =
  "471025930138-cgahb4fj4p5tignjog5k62kp96ivcbti.apps.googleusercontent.com";
export const API_BASE = getApiBase();

export default function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (
      !accessToken ||
      !tokenExpiration ||
      new Date(tokenExpiration) < new Date()
    ) {
      handleLogout();
      return;
    } else {
      setIsAuthorized(true);
    }
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("actorKey");
    localStorage.removeItem("actorName");
    localStorage.removeItem("tokenExpiration");
    setIsAuthorized(false);
    setLoading(false);
  };

  useEffect(() => {
    checkToken();
  }, []);

  if (loading) return null;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Helmet>
        <title>{getHelmetTitle()}</title>
      </Helmet>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={!isAuthorized ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={isAuthorized ? <Home /> : <Navigate to="/login" />}
          />
          <Route
            path="/search"
            element={
              isAuthorized ? <SearchResults /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/reporting"
            element={isAuthorized ? <Reporting /> : <Navigate to="/login" />}
          />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}
