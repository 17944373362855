import axios from "axios";
import React, { useEffect, useState } from "react";

import { Box, IconButton } from "@mui/material";

import { Undo } from "@mui/icons-material";

import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";

import { API_BASE } from "@/App";
import { fromNow } from "@/utils";

export default function EventHistoryContainer({ searchData, open }) {
  const [events, setEvents] = useState([]);

  const undoEvent = async (event_id) => {
    console.log("undoing event...");
    const endpoint = API_BASE + "undo_event";
    const response = await axios.post(endpoint, {
      event_id: event_id,
    });
    window.location.reload();
  };

  const fetchEvents = async () => {
    const endpoint = API_BASE + "events";
    const response = await axios.get(endpoint, {
      params: { search_uid: searchData.uid },
    });

    setEvents(response.data);
  };

  useEffect(() => {
    if (searchData && open) {
      fetchEvents();
    }
  }, [searchData, open]);

  const columns = [
    {
      field: "undo",
      width: 60,
      renderCell: (params) =>
        params.row.actor_key === localStorage.getItem("actorKey") &&
        params.row.event_type !== "update" && (
          <IconButton onClick={() => undoEvent(params.row.id)}>
            <Undo color="warning" />
          </IconButton>
        ),
    },
    {
      field: "domain",
      width: 120,
    },
    {
      field: "type",
      width: 100,
    },
    {
      field: "created",
      type: "number",
      valueFormatter: (value) => fromNow(value),
      // width: 240,
    },
    {
      field: "analyst",
      width: 130,
    },
  ];

  return (
    <Box sx={{ my: 2 }}>
      <DataGridPremium
        getRowId={(row) => row.id}
        rows={events}
        columns={columns}
        density="compact"
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          columns: {
            columnVisibilityModel: {
              search_label: false,
              search_uid: false,
            },
          },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        pagination
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    </Box>
  );
}
