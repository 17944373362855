import React from "react";
import moment from "moment";

import { ICON_MAP } from "@/fragments/Constants";

export const getIcon = (stage, color) => {
  const icon = ICON_MAP[stage];

  if (!icon) {
    console.warn(`No icon found for stage: ${stage}`);
    return null;
  }

  return React.cloneElement(icon, { color: color });
};

export const clearColumnOrder = (searchUid) => {
  localStorage.removeItem(`${searchUid}_columnOrder`);
};

export const setColumnOrder = (searchUid, columnOrder) => {
  localStorage.setItem(searchUid, JSON.stringify(columnOrder));
};

export const getCompactState = () => {
  return JSON.parse(localStorage.getItem("compact")) || false;
};

export const toggleCompact = () => {
  const compact = getCompactState();
  localStorage.setItem("compact", JSON.stringify(!compact));
  window.location.reload();
};

export const getApiBase = () => {
  const url = window.location.href;
  if (url.includes("staging")) {
    return "https://api-dot-targetselect-staging.uc.r.appspot.com/";
  } else if (url.includes("dev")) {
    console.log("dev API");
    return "https://api-dot-targetselect-dev.uc.r.appspot.com/";
  } else if (url.includes("localhost")) {
    return "http://localhost:8080/";
  } else {
    return "https://api-dot-targetselect-prod.uc.r.appspot.com/";
  }
};

export const getHelmetTitle = () => {
  let title = "TargetSelect.ai";
  if (window.location.origin.includes("localhost")) {
    title = "[dev] " + title;
  } else if (window.location.origin.includes("staging")) {
    title = "[staging] " + title;
  }
  return title;
};

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "1s",
    ss: "%ss",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY",
  },
});

export const fromNow = (timestamp) => {
  return moment.unix(timestamp).fromNow();
};

export const getWeekColor = (weekChar) => {
  const currentWeekNumber = Math.ceil(
    (new Date().getTime() -
      new Date(new Date().getFullYear(), 0, 1).getTime()) /
      (1000 * 60 * 60 * 24 * 7)
  );
  const currentWeekChar = currentWeekNumber % 2 === 0 ? "A" : "B";
  return currentWeekChar === weekChar ? "primary" : "info";
};
