import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import {
  AppBar,
  Box,
  Chip,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";

import { Add, ArrowBackIos } from "@mui/icons-material";

import { ICON_MAP, LABEL_MAP } from "@/fragments/Constants";

import { API_BASE } from "@/App";

import { toggleCompact } from "@/utils";

export default function PageAppBar({
  searchData,
  currentView,
  fetchTargetCount,
  fetchTargets,
}) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const runCriteriaSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.post(API_BASE + "criteria_search", {
        search_uid: searchData.uid,
        // criteria: criteria, // this is really "new" criteria...
      });

      console.log(currentView);
      if (currentView !== "create") {
        window.location.href = `search?searchKey=${searchData.uid}&currentView=create`;
      }
      console.log("Search Results:", response.data);
      fetchTargetCount();
      fetchTargets();
    } catch (error) {
      console.error("Error running criteria search:", error);
      // setError("Failed to run search. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppBar
      variant="transparent"
      position="static"
      sx={{ width: "calc(100vw - 240px)" }}
    >
      <Toolbar
        sx={{ justifyContent: "space-between", py: 2 }}
        style={{ paddingLeft: 0 }}
      >
        <Box
          id="left-side"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <IconButton onClick={() => navigate("/")}>
            <ArrowBackIos />
          </IconButton>
          <Typography variant="h6">{searchData?.label}</Typography>
          <Chip
            label={LABEL_MAP[currentView]}
            // icon={ICON_MAP[currentView]}
            size="large"
            variant="outlined"
          />
        </Box>

        <Box>
          <Switch
            checked={JSON.parse(localStorage.getItem("compact")) || false}
            onChange={toggleCompact}
            inputProps={{ "aria-label": "controlled" }}
            sx={{ mx: 10 }}
          />

          <Tooltip title="Deliver next 25 results from Grata based on the current criteria">
            <Button
              variant="outlined"
              color="auto"
              onClick={runCriteriaSearch}
              disabled={loading}
              startIcon={<Add />}
              sx={{ borderRadius: 20 }}
            >
              Next 25 Results
            </Button>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
