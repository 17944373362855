import axios from "axios";
import { useState } from "react";

import {
  DataGridPremium,
  GridToolbar,
  useGridApiRef,
} from "@mui/x-data-grid-premium";

import { API_BASE } from "@/App.js";
import EditTextarea from "@/components/EditTextArea";
import { fromNow, getWeekColor } from "@/utils";
import { Whatshot } from "@mui/icons-material";
import { Box, Chip, Link, Skeleton, Typography } from "@mui/material";
import SearchCommentsDrawer from "./SearchCommentsDrawer";

export default function SearchesTable({ searches }) {
  const unpackedSearches = searches.map((search) => {
    const meta = search.meta || {}; // Extract meta field
    const { type, products, services, end_market, geographies, day, week } =
      meta; // Extract specific fields

    return {
      ...search,
      type, // Add 'type' from meta
      products, // Add 'products' from meta
      services, // Add 'services' from meta
      end_market, // Add 'end_market' from meta
      geographies, // Add 'geographies' from meta
      day,
      week,
    };
  });

  const rows = unpackedSearches;

  const handleUpdate = async (newRow, oldRow) => {
    const findChanges = (newValue, oldValue) => {
      const changes = {};
      for (const [key, value] of Object.entries(newValue)) {
        if (oldValue[key] !== value) {
          changes[key] = value;
        }
      }
      return changes;
    };
    const endpoint = API_BASE + "update_search";
    const response = axios.post(endpoint, {
      search_uid: newRow.uid,
      data: findChanges(newRow, oldRow),
      actor_key: localStorage.getItem("actorKey"),
    });
    return newRow;
    // return null;
  };

  const [showComments, setShowComments] = useState(false);
  const [search, setSearch] = useState(null);

  const columns = [
    {
      field: "analyst",
    },
    {
      field: "week",
      editable: true,
      width: 80,
      type: "singleSelect",
      valueOptions: ["A", "B"],
      renderCell: ({ row }) =>
        row.week && (
          <Chip label={row.week} size="small" color={getWeekColor(row.week)} />
        ),
    },
    {
      field: "day",
      editable: true,
      width: 80,
      type: "singleSelect",
      valueOptions: ["1-Mon.", "2-Tue.", "3-Wed.", "4-Thu.", "5-Fri."],
    },
    {
      field: "updated",
      width: 100,
      type: "number",
      valueFormatter: (value) => fromNow(value),
    },
    {
      field: "status",
      width: 120,
      editable: true,
      type: "singleSelect",
      // filterable: true,
      valueOptions: [
        "starting",
        "active",
        "holding",
        "blocked",
        "completed",
        "paused",
        "cancelled",
      ],
    },
    {
      field: "label",
      width: 220,
      headerName: "search",
      renderCell: ({ row }) => (
        <Link
          href={`/search?searchKey=${row.uid}&currentView=land`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            textTransform: "uppercases",
            color: "#04FFFF",
          }}
        >
          {row.label}
        </Link>
      ),
    },
    {
      field: "type",
      editable: true,
      type: "singleSelect",
      valueOptions: ["Platform", "Add-On", "Market Map"],
    },
    {
      field: "products",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="primary"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "services",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  color="success"
                  size="small"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "end_market",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="secondary"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
    {
      field: "geographies",
      flex: 1,
      editable: true,
      renderEditCell: (params) => <EditTextarea {...params} />,
      renderCell: ({ value }) => (
        <Box>
          {value &&
            value
              .split(",")
              .map((val, index) => (
                <Chip
                  label={val.trim()}
                  key={index}
                  size="small"
                  color="info"
                  style={{ marginRight: "5px" }}
                />
              ))}
        </Box>
      ),
    },
  ];

  const apiRef = useGridApiRef();

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Whatshot color="secondary" sx={{ fontSize: 24, mr: 1 }} />
        <Typography variant="h6">Active Searches</Typography>
      </Box>
      {searches.length === 0 && (
        <Skeleton
          variant="rectangular"
          height={340}
          sx={{ borderRadius: "5px" }}
        />
      )}

      {searches.length > 0 && (
        <DataGridPremium
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.uid}
          density="compact"
          autoHeight
          // autoPageSize
          processRowUpdate={handleUpdate}
          onProcessRowUpdateError={(error, row, field, newValue) => {
            console.log(error, row, field, newValue);
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
            rowGrouping: { model: ["analyst"] },
            sorting: {
              sortModel: [{ field: "updated", sort: "desc" }],
            },
            filter: {
              filterModel: {
                items: [
                  {
                    field: "status",
                    operator: "is",
                    value: "active",
                  },
                ],
              },
            },
            columns: {
              columnVisibilityModel: {
                analyst: false,
              },
            },
            aggregation: {
              model: {
                updated: "max",
              },
            },
          }}
          pagination
          pageSizeOptions={[5, 10, 20, 50, 100]}
          unstable_cellSelection
          experimentalFeatures={{ clipboardPaste: true }}
          unstable_ignoreValueFormatterDuringExport
          sx={{
            "& .MuiDataGrid-aggregationColumnHeaderLabel": {
              color: "white",
              opacity: 0.6,
              fontSize: "10px",
            },
            "& .MuiDataGrid-footerCell": {
              color: "#04FFFF",
            },
          }}
        />
      )}

      <SearchCommentsDrawer
        searchData={search}
        open={showComments}
        setOpen={setShowComments}
      />
    </Box>
  );
}
