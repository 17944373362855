import React, { useState } from "react";
import axios from "axios";

import { Button, CircularProgress, Container, Box } from "@mui/material";

import GoogleIcon from "@mui/icons-material/Google";
import { useGoogleLogin } from "@react-oauth/google";

import { API_BASE } from "@/App.js";
import logo from "@/images/logo.svg";
import Footer from "@/fragments/Footer";

export default function LoginContainer() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleLogin = async (response) => {
    setLoading(true);
    setError(null);

    try {
      const access_token = response["access_token"];
      const profile = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      );

      const actor = await axios.post(API_BASE + "actor", {
        email: profile.data.email,
        name: profile.data.name,
      });

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);

      localStorage.setItem("tokenExpiration", expirationDate.toISOString());
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("actorKey", actor.data["key"]);
      localStorage.setItem("actorName", actor.data["name"]);

      window.location.href = "/";

      // navigate("/"); // this didnt work w app loading sequence
    } catch (err) {
      setError("Login failed. Please try again.");
      console.error("Login error:", err);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleLogin,
    onError: (error) => {
      setError("Google login failed. Please try again.");
      console.error("Login Failed:", error);
    },
  });

  return (
    <Container
      style={{
        marginTop: 200,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={logo} alt="Logo" style={{ width: 500 }} />
      <Box sx={{ mt: 5, mb: 50 }}>
        {error && <div style={{ color: "red", marginBottom: 16 }}>{error}</div>}
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="outlined"
            size="large"
            color="inherit"
            startIcon={<GoogleIcon />}
            onClick={login}
          >
            Login with Google
          </Button>
        )}
      </Box>
      <Footer />
    </Container>
  );
}
